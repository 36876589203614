import { inject, Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, map, Observable } from 'rxjs';

import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';

import { LaunchDarklyService, LDFeatureKey } from './launch-darkly.service';


@Injectable({
	providedIn: 'root'
})
export class FeatureService {
	readonly #isUserSealightsAdmin$ = toObservable(inject(AuthStore).isSealightsAdminUser);
	readonly #isUserDevOps$ = toObservable(inject(AuthStore).isDevopsUser);
	readonly #ldService = inject(LaunchDarklyService);

	isFeatureOn(key: LDFeatureKey): Observable<boolean> {
		return this.#ldService.getFeatureFlagValue(key).pipe(
			map((isOn) => !!isOn)
		);
	}

	isAdminOrFeatureActive(key: LDFeatureKey): Observable<boolean> {
		return combineLatest([
			this.#isUserSealightsAdmin$,
			this.isFeatureOn(key)
		]).pipe(
			map(([isSealightsAdmin, isFeatureOn]) => isSealightsAdmin || isFeatureOn)
		);
	}

	isAdminAndFeatureActive(key: LDFeatureKey): Observable<boolean> {
		return combineLatest([
			this.#isUserSealightsAdmin$,
			this.isFeatureOn(key)
		]).pipe(
			map(([isSealightsAdmin, isFeatureOn]) => isSealightsAdmin && isFeatureOn)
		);
	}

	isNonAdminAndFeatureActive(key: LDFeatureKey): Observable<boolean> {
		return combineLatest([
			this.#isUserSealightsAdmin$,
			this.isFeatureOn(key)
		]).pipe(
			map(([isSealightsAdmin, isFeatureOn]) => !isSealightsAdmin && isFeatureOn)
		);
	}

	isDevOpsAndFeatureActive(key: LDFeatureKey): Observable<boolean> {
		return combineLatest([
			this.#isUserDevOps$,
			this.isFeatureOn(key)
		]).pipe(
			map(([isUserDevOps, isFeatureOn]) => isUserDevOps && isFeatureOn)
		);
	}

	subscribeToFlagChanges(flagKey: LDFeatureKey, callback: (newValue: boolean) => void): void {
		this.#ldService.subscribeToFlagChanges(flagKey, callback);
	}

	unsubscribeFromFlagChangeListener(flagKey: LDFeatureKey, callback: (newValue: boolean) => void): void {
		this.#ldService.unsubscribeFromFlagChangeListener(flagKey, callback);
	}
}
